var searchKeys = [{
  key: 'dates',
  label: '发售时间',
  placeholder: ["开始时间", "结束时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
}, {
  key: "useruid",
  label: "发售用户ID",
  placeholder: "用户ID",
  required: false,
  input: true
}, {
  key: "usernickname",
  label: "发售用户昵称",
  placeholder: "用户昵称",
  required: false,
  input: true
}, {
  key: "txhash",
  label: "发售Hash",
  placeholder: "发售Hash",
  required: false,
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var tableColumns = [{
  title: "Hood ID",
  dataIndex: "hoodserialnum",
  key: "hoodserialnum",
  width: 140
}, {
  title: "所属用户昵称",
  dataIndex: "username",
  key: "username",
  width: 140,
  scopedSlots: {
    customRender: "username"
  }
}, {
  title: '最新价格（ETH）',
  dataIndex: "price",
  key: "price",
  width: 140,
  scopedSlots: {
    customRender: "price"
  }
}, {
  title: "持有用户数",
  dataIndex: "holders",
  key: "holders",
  width: 120,
  scopedSlots: {
    customRender: "holders"
  }
}, {
  title: '持有份数',
  dataIndex: "supply",
  key: "supply",
  width: 120,
  scopedSlots: {
    customRender: "supply"
  }
}, {
  title: "发售时间",
  dataIndex: "releasetime",
  key: "releasetime",
  width: 150,
  scopedSlots: {
    customRender: "releasetime"
  }
}, {
  title: "发售Hash",
  dataIndex: "txhash",
  key: "txhash",
  width: 140,
  scopedSlots: {
    customRender: "txhash"
  }
}, {
  title: "状态",
  dataIndex: "hoodrelease",
  key: "hoodrelease",
  width: 80,
  scopedSlots: {
    customRender: "hoodrelease"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: 140,
  scopedSlots: {
    customRender: "action"
  }
}];
export { searchKeys, tableColumns };